var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('PageWrapDefault',{attrs:{"errorMessage":_vm.errorMessage,"successMessage":_vm.successMessage,"showSearch":false,"topActionButtonPrimary":{
    text: 'Save',
    key: 'save',
    disabled: _vm.disablePrimaryToolbar,
    loading: _vm.makingApiRequest
  },"topActionButtonSecondary":{
    text: 'Cancel',
    key: 'cancel'
  }},on:{"toolbarSelectItem":_vm.onToolbarItemClick}},[(_vm.$getCurrentUser && _vm.$getCurrentUser.role == 'admin')?_c('TabMenu',{staticClass:"mb-12",attrs:{"menuItems":_vm.menuItems,"selectedTabKey":_vm.selectedTabKey},on:{"tabClicked":_vm.onTabItemClick}}):_vm._e(),(!_vm.resourceNotFound && !_vm.showActivities)?_c('div',[(_vm.initialized)?_c('div',[_c('form-builder',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.makingApiRequest),expression:"makingApiRequest"}],ref:"formElement",attrs:{"schemas":_vm.formFields,"formValues":_vm.editing,"element-loading-text":_vm.loadingText,"disabled":!_vm.$isCurrentUserAdmin && !_vm.$isCurrentUserUnderwriter},on:{"validationChanged":function($event){_vm.validationData = $event},"formFieldChanged":_vm.formFieldChangedHandler}})],1):_vm._e()]):(_vm.showActivities)?_c('ActivitiesView',{attrs:{"queryOverride":_vm.queryOverride},scopedSlots:_vm._u([{key:"default",fn:function({ activity }){return [_c('ActivitiesTimelineItem',{attrs:{"activity":activity}})]}}])}):_c('ResourceNotFound',{attrs:{"title":_vm.resourceNotFoundMsg,"actionButtonTitle":"Back to Mortgages","actionRedirectPath":"/admin/mortgages"}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }